import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2, ArrowRight } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const Success: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      console.log('Current user ID:', user.id);
    }
  }, [user]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-primary-50 via-white to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto text-center">
          <div className="w-20 h-20 bg-primary-100 rounded-full flex items-center justify-center mx-auto mb-8">
            <CheckCircle2 className="text-primary-600 w-12 h-12" />
          </div>
          
          <h1 className="text-4xl font-bold mb-6">
            Thank You for Your Purchase!
          </h1>
          
          <p className="text-xl text-gray-600 mb-8">
            Your smile transformation journey begins now. Check your email for access details and next steps.
          </p>

          <div className="bg-white rounded-xl p-6 shadow-sm mb-8">
            <h2 className="text-xl font-semibold mb-4">What's Next?</h2>
            <ul className="space-y-4 text-left">
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Access your personalized smile enhancement routine</span>
              </li>
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Review your detailed recommendations</span>
              </li>
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Track your progress with our monitoring tools</span>
              </li>
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Get started with expert smile enhancement tips</span>
              </li>
            </ul>
          </div>

          <button
            onClick={() => navigate('/')}
            className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2 group"
          >
            Return to Dashboard
            <ArrowRight className="group-hover:translate-x-1 transition-transform" size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;