import OpenAI from 'openai';
import { getOrCreateSession } from '../lib/session';
import { supabase } from '../lib/supabase';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export interface AnalysisResult {
  colour: { score: number; description: string };
  shape: { score: number; description: string };
  spacing: { score: number; description: string };
  alignment: { score: number; description: string };
  treatment: string;
}

function logApiDetails(stage: string, details: any) {
  console.group(`🔍 OpenAI API [${stage}] - ${new Date().toISOString()}`);
  console.log('Details:', details);
  console.groupEnd();
}

function logApiError(stage: string, error: any) {
  console.group(`❌ OpenAI API Error [${stage}] - ${new Date().toISOString()}`);
  console.error('Error Details:', {
    message: error?.message,
    name: error?.name,
    code: error?.code,
    type: error?.type,
    param: error?.param,
    status: error?.status,
    headers: error?.headers,
    response: {
      data: error?.response?.data,
      status: error?.response?.status,
      headers: error?.response?.headers,
    },
    raw: error
  });
  console.groupEnd();
}

function logGptResponse(response: any) {
  console.group('🤖 GPT Response');
  console.log('\nModel:', response.model);
  console.log('Created:', new Date(response.created * 1000).toISOString());
  console.log('\nResponse Content:');
  console.log('----------------------------------------');
  console.log(response.choices[0]?.message?.content || 'No content');
  console.log('----------------------------------------');
  console.log('\nUsage Statistics:');
  console.log('- Prompt Tokens:', response.usage?.prompt_tokens);
  console.log('- Completion Tokens:', response.usage?.completion_tokens);
  console.log('- Total Tokens:', response.usage?.total_tokens);
  console.groupEnd();
}

function parseTextResponse(text: string): AnalysisResult {
  console.log('Parsing response text:', text);
  
  const sections = text.split('\n\n').filter(Boolean);
  const result: Record<string, any> = {};
  
  const treatmentIndex = sections.findIndex(section => section.trim().startsWith('Treatment:'));
  if (treatmentIndex !== -1) {
    const treatmentAndBeyond = sections.slice(treatmentIndex).join('\n\n');
    result.treatment = treatmentAndBeyond.replace(/^Treatment:\s*/i, '').trim();
  }
  
  sections.forEach(section => {
    const lines = section.split('\n').map(line => line.trim());
    
    // Find the category and score
    const scoreMatch = lines[0]?.match(/^(Colour|Shape|Spacing|Alignment):\s*(\d+)/i);
    if (scoreMatch) {
      const category = scoreMatch[1].toLowerCase();
      const score = parseInt(scoreMatch[2], 10);
      
      // Find all bullet points that start with + or -
      const bulletPoints = lines
        .slice(1) // Skip the score line
        .filter(line => line.startsWith('+') || line.startsWith('-'))
        .join('\n\n'); // Add double line break between bullet points
      
      // If no bullet points found, try to find a description line
      const description = bulletPoints || 
        lines.find(line => line.startsWith('Description:'))?.replace(/^Description:\s*/i, '') ||
        'Analysis not available';
      
      result[category] = {
        score: Math.min(Math.max(score, 0), 100),
        description
      };
    }
  });

  console.log('Parsed result:', result);

  const allScoresZero = ['colour', 'shape', 'spacing', 'alignment'].every(
    key => result[key]?.score === 0
  );
  
  if (allScoresZero) {
    throw new Error("Smile not clearly visible in the image. Please use another photo with a clear, front-facing smile.");
  }

  return {
    colour: result.colour || { score: 0, description: 'Analysis not available' },
    shape: result.shape || { score: 0, description: 'Analysis not available' },
    spacing: result.spacing || { score: 0, description: 'Analysis not available' },
    alignment: result.alignment || { score: 0, description: 'Analysis not available' },
    treatment: result.treatment || 'Treatment recommendation not available'
  };
}

export async function analyzeSkinImage(
  imageBase64: string,
  firstName?: string,
  age?: string,
  gender?: string | null,
  budget?: number,
  email?: string,
  location?: string,
  concerns?: string[]
): Promise<AnalysisResult> {
  try {
    logApiDetails('Starting Analysis', {
      hasImage: !!imageBase64,
      imageSize: imageBase64.length,
      budget,
      hasEmail: !!email,
      hasLocation: !!location,
      concerns
    });

    const base64Data = imageBase64.replace(/^data:image\/\w+;base64,/, '');

    const requestPayload = {
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: `You are a dental expert evaluating my smile aesthetics. For each category, give a concise analysis in plain language and a score between 60 and 95. If no smile is clearly visible, assign 0 to all categories.

Format your response EXACTLY as follows:

Colour: [score]
Description:
+ [positive point about tooth colour]
- [negative point or area for improvement, be specific]
- [negative point or area for improvement, be specific]


Shape: [score]
Description:
+ [positive point about smile curve/tooth shapes, be specific]
- [negative point or area for improvement]
- [negative point or area for improvement, be specific]

Spacing: [score]
Description:
+ [positive point about teeth spacing, be specific]
- [negative point or area for improvement, be specific]
- [negative point or area for improvement]

Alignment: [score]
Description:
+ [positive point about teeth alignment, be specific]
- [negative point or area for improvement, be specific]
- [negative point or area for improvement]

Treatment: [A very concise outline of the most suitable treatment option(s) for this smile. Written with authority, urgency and persuasion to make the user more likely to listen.]

Write as if you are speaking directly to the person, using "your smile" instead of "this/the smile" and simple, direct language. No jargon. Keep the descriptions concise. Do not use any asterisks.`
        },
        {
          role: "user",
          content: [
            {
              type: "text",
              text: "Analyze this smile image and provide detailed scores and descriptions for colour, shape, spacing, and alignment. Focus on specific areas that could be improved. If no smile is clearly visible, assign 0 to all scores."
            },
            {
              type: "image_url",
              image_url: {
                url: `data:image/jpeg;base64,${base64Data}`
              }
            }
          ]
        }
      ],
      max_tokens: 1000
    };

    logApiDetails('Request', {
      model: requestPayload.model,
      messageCount: requestPayload.messages.length
    });

    const response = await openai.chat.completions.create(requestPayload);
    const content = response.choices[0]?.message?.content;

    if (!content) {
      throw new Error("No response content received from OpenAI");
    }

    logGptResponse(response);
    
    const result = parseTextResponse(content);

    // Store analysis in memory instead of database
    console.log('Analysis completed:', {
      email,
      location,
      concerns,
      scores: {
        colour: result.colour.score,
        shape: result.shape.score,
        spacing: result.spacing.score,
        alignment: result.alignment.score
      }
    });

    return result;
  } catch (error) {
    logApiError('Analysis', error);
    
    if (error?.code === 'model_not_found') {
      throw new Error("The AI model is currently unavailable. Please try again later.");
    } else if (error?.code === 'context_length_exceeded') {
      throw new Error("The image is too complex to analyze. Please try a different photo.");
    } else if (error?.status === 429) {
      throw new Error("Too many requests. Please wait a moment and try again.");
    } else if (error instanceof Error) {
      throw new Error(error.message);
    }
    
    throw new Error("An unexpected error occurred during analysis. Please try again.");
  }
}