import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import { migrateSessionToUser } from '../lib/session';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  email: string | null;
}

const AuthContext = createContext<AuthContextType>({ 
  user: null, 
  loading: true,
  email: null 
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    console.group('AuthContext Initialization');
    
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      console.log('Initial session:', {
        hasSession: !!session,
        email: session?.user?.email
      });
      
      if (session?.user) {
        setUser(session.user);
        setEmail(session.user.email);
        // Migrate any existing session data to the user
        migrateSessionToUser(session.user.id).catch(console.error);
      } else {
        setUser(null);
        setEmail(null);
      }
      setLoading(false);
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log('Auth state changed:', {
        event: _event,
        hasSession: !!session,
        email: session?.user?.email
      });
      
      if (session?.user) {
        setUser(session.user);
        setEmail(session.user.email);
        // Migrate any existing session data to the user
        migrateSessionToUser(session.user.id).catch(console.error);
      } else {
        setUser(null);
        setEmail(null);
      }
      setLoading(false);
    });

    console.groupEnd();

    return () => subscription.unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, email }}>
      {children}
    </AuthContext.Provider>
  );
};