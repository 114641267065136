import React, { useState, useEffect } from 'react';
import { 
  Scan, 
  Camera, 
  Sparkles, 
  ListChecks, 
  Shield, 
  Award, 
  Users, 
  Star,
  ArrowRight,
  CheckCircle2,
  Smile,
  Zap,
  Clock,
  Target
} from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PhotoModal from '../components/PhotoModal';

const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      console.log('Current user ID:', user.id);
    }
  }, [user]);

  useEffect(() => {
    const state = location.state as { showAuth?: boolean } | null;
    if (state?.showAuth) {
      window.dispatchEvent(new CustomEvent('openAuthModal'));
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const steps = [
    {
      title: 'Take A Photo',
      description: 'Capture your smile with your camera or upload a selfie',
      icon: Camera
    },
    {
      title: 'AI Analyzes Your Smile',
      description: 'Our advanced AI examines multiple aspects of your smile in seconds',
      icon: Sparkles
    },
    {
      title: 'Get Personalized Tips',
      description: 'Receive tailored recommendations for a more confident smile',
      icon: ListChecks
    }
  ];

  const features = [
    {
      title: 'Advanced AI Technology',
      description: 'Powered by state-of-the-art smile recognition and analysis',
      icon: Sparkles,
      stats: '98% accuracy'
    },
    {
      title: 'Instant Analysis',
      description: 'Get comprehensive smile analysis in seconds',
      icon: Scan,
      stats: '< 10 seconds'
    },
    {
      title: 'Privacy First',
      description: 'Your photos are analyzed instantly and never shared',
      icon: Shield,
      stats: '100% private'
    },
    {
      title: 'Expert Backed',
      description: 'Developed with dental professionals',
      icon: Award,
      stats: '10+ experts'
    }
  ];

  const testimonials = [
    {
      name: 'Emily R.',
      role: 'Verified User',
      content: "The analysis was incredibly accurate. And the recommendations have made such a difference.",
      image: 'https://phosphor.utils.elfsightcdn.com/?url=https%3A%2F%2Finstagram.fayt2-2.fna.fbcdn.net%2Fv%2Ft51.2885-19%2F348705236_907482327213649_3279227753836268887_n.jpg%3Fstp%3Ddst-jpg_s150x150_tt6%26_nc_ht%3Dinstagram.fayt2-2.fna.fbcdn.net%26_nc_cat%3D101%26_nc_oc%3DQ6cZ2AEnnoyv9OqOAyfWKCWFwTEsQrDbL-JX8MKOJeBXycBj3FcqzaE9EjoE2J7KG7aH6-nAJ1rYvYHE3JrCrauOQVzt%26_nc_ohc%3DQBc0qdbUhbIQ7kNvgEkbaTu%26_nc_gid%3D0a254b3f21bc4b99b70a96c1f1a1bc32%26edm%3DANg5bX4BAAAA%26ccb%3D7-5%26oh%3D00_AYBcvU7S5RMwkdKmled09a-0OYMO93MpUbdvdkTIZhfhZg%26oe%3D67BBF987%26_nc_sid%3D0055be'
    },
    {
      name: 'Mike T.',
      role: 'Verified User',
      content: "The personalized recommendations were spot-on. I've seen some improvements in my smile following their recommendations.",
      image: 'https://phosphor.utils.elfsightcdn.com/?url=https%3A%2F%2Fscontent-atl3-2.cdninstagram.com%2Fv%2Ft51.2885-19%2F462279505_559673903201344_9080878024831613150_n.jpg%3Fstp%3Ddst-jpg_s150x150_tt6%26_nc_ht%3Dscontent-atl3-2.cdninstagram.com%26_nc_cat%3D104%26_nc_oc%3DQ6cZ2AGAzRnczAO4fFXystjYCXzlu0361Zh8Iza9bJaFgOV0cWNErWOTRSwQTdXmZuj7qxQ%26_nc_ohc%3DuPQ59yPF7SoQ7kNvgFq4H1C%26_nc_gid%3D5e1ea021594e49428803e3d6460f419c%26edm%3DANg5bX4BAAAA%26ccb%3D7-5%26oh%3D00_AYDz-VK9ZGO24ZgE17ldwvlBBSXg6wJoqCPCUd2bhhcnCg%26oe%3D67BBDCC2%26_nc_sid%3D0055be'
    },
    {
      name: 'Sarah Chen',
      role: 'Verified User',
      content: "What impressed me most was how detailed the analysis was. It helped me understand exactly what I needed to focus on.",
      image: 'https://phosphor.utils.elfsightcdn.com/?url=https%3A%2F%2Finstagram.fayt2-2.fna.fbcdn.net%2Fv%2Ft51.2885-19%2F473525544_8697946953661561_311743952407910674_n.jpg%3Fstp%3Ddst-jpg_s150x150_tt6%26_nc_ht%3Dinstagram.fayt2-2.fna.fbcdn.net%26_nc_cat%3D101%26_nc_oc%3DQ6cZ2AEnnoyv9OqOAyfWKCWFwTEsQrDbL-JX8MKOJeBXycBj3FcqzaE9EjoE2J7KG7aH6-nAJ1rYvYHE3JrCrauOQVzt%26_nc_ohc%3DMBcqITp9aP4Q7kNvgEY97aH%26_nc_gid%3D0a254b3f21bc4b99b70a96c1f1a1bc32%26edm%3DANg5bX4BAAAA%26ccb%3D7-5%26oh%3D00_AYAwWJPWwF38VXza9E5flPtISGsycNz5Ids65OkfVOOXAA%26oe%3D67BBED3F%26_nc_sid%3D0055be'
    }
  ];

  const benefits = [
    {
      icon: Target,
      title: "Personalized Analysis",
      description: "Get insights tailored to your unique smile"
    },
    {
      icon: Zap,
      title: "Instant Results",
      description: "See your smile score in seconds"
    },
    {
      icon: Clock,
      title: "Quick Process",
      description: "Complete your analysis in under 2 minutes"
    }
  ];

  return (
    <div className="min-h-screen">
      <PhotoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      
      <div className="relative bg-gradient-to-b from-primary-50 via-white to-white animate-gradient overflow-hidden">
        <div className="absolute inset-0 bg-grid-primary-100/[0.05] bg-[size:20px_20px]" />
        
        {/* Hero Section with Increased Whitespace */}
        <div className="container mx-auto px-4 pt-24 pb-24">
          <div className="max-w-4xl mx-auto text-left md:text-center relative">
            <h1 className="text-5xl md:text-7xl font-bold mb-4 animate-slide-up">
              Scan Your <span className="text-primary-500">Smile</span><span className="text-primary-500">.</span>
            </h1>
            <ul className="space-y-4 mb-6">
              <li className="flex items-center md:justify-center text-xl md:text-2xl text-gray-600 animate-slide-up stagger-delay-1">
                <CheckCircle2 className="text-primary-500 mr-2" size={24} />
                Instant analysis of your smile.
              </li>
              <li className="flex items-center md:justify-center text-xl md:text-2xl text-gray-600 animate-slide-up stagger-delay-2">
                <CheckCircle2 className="text-primary-500 mr-2" size={24} />
                Personalized recommendations.
              </li>
              <li className="flex items-center md:justify-center text-xl md:text-2xl text-gray-600 animate-slide-up stagger-delay-3">
                <CheckCircle2 className="text-primary-500 mr-2" size={24} />
                Enhance your smile today.
              </li>
            </ul>

            {/* Button & Info Section with Extra Gap Under the Text */}
            <div className="flex flex-col items-center gap-4 animate-slide-up stagger-delay-4">
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-primary-500 text-white text-lg px-8 py-4 rounded-full hover:bg-primary-600 transition-all transform hover:scale-105 flex items-center gap-2 group w-full md:w-auto justify-center"
              >
                <Smile className="group-hover:rotate-12 transition-transform" size={24} />
                Start Your Free Analysis Now
              </button>
              <p className="text-sm text-gray-500 mb-8 md:mb-4">
                No registration required • 100% Free • Takes 2 minutes
              </p>
            </div>

            {/* Quick Benefits */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 animate-slide-up stagger-delay-3">
              {benefits.map((benefit, index) => (
                <div key={index} className="bg-white/80 backdrop-blur-sm p-4 rounded-xl shadow-sm">
                  <benefit.icon className="text-primary-500 mb-2" size={24} />
                  <h3 className="font-semibold text-gray-900">{benefit.title}</h3>
                  <p className="text-sm text-gray-600">{benefit.description}</p>
                </div>
              ))}
            </div>

            {/* Trust Indicators */}
            <div className="mt-12 grid grid-cols-2 md:grid-cols-4 gap-6 text-center animate-slide-up stagger-delay-3">
              {[
                { label: 'Happy Users', value: '5K+' },
                { label: 'Smiles Analyzed', value: '3K+' },
                { label: 'Expert Reviews', value: '10+' },
                { label: 'Satisfaction', value: '99%' },
              ].map((stat, index) => (
                <div key={index} className="space-y-1">
                  <div className="text-2xl md:text-3xl font-bold text-primary-600">{stat.value}</div>
                  <div className="text-sm text-gray-500">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Process Steps */}
        <div id="how-it-works" className="container mx-auto px-4 py-16 bg-white">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
            How It Works
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {steps.map((step, index) => (
              <div key={index} className="relative group">
                <div className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 group-hover:-translate-y-2">
                  <div className="w-14 h-14 bg-primary-100 rounded-full flex items-center justify-center mb-4 mx-auto group-hover:bg-primary-200 transition-colors">
                    <step.icon size={28} className="text-primary-600 group-hover:scale-110 transition-transform" />
                  </div>
                  <h3 className="text-lg font-semibold mb-2 text-primary-700">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-4 w-8 h-0.5 bg-primary-200" />
                )}
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-primary-500 text-white px-6 py-3 rounded-xl hover:bg-primary-600 transition-all inline-flex items-center gap-2 group"
            >
              <Smile className="group-hover:rotate-12 transition-transform" size={20} />
              Try It Now - It's Free
            </button>
          </div>
        </div>

        {/* Features Grid */}
        <div className="container mx-auto px-4 py-16 bg-gradient-to-b from-white to-primary-50">
          <div className="max-w-5xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
              Why Choose ScanSmile
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              {features.map((feature, index) => (
                <div key={index} className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 hover:-translate-y-1 group">
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 bg-primary-100 rounded-xl flex items-center justify-center group-hover:bg-primary-200 transition-colors">
                      <feature.icon size={24} className="text-primary-600 group-hover:scale-110 transition-transform" />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold mb-2 text-primary-700">{feature.title}</h3>
                      <p className="text-gray-600 mb-3">{feature.description}</p>
                      <div className="flex items-center gap-2 text-primary-600 font-medium">
                        <CheckCircle2 size={16} />
                        {feature.stats}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-8">
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-primary-500 text-white px-6 py-3 rounded-xl hover:bg-primary-600 transition-all inline-flex items-center gap-2 group"
              >
                Get Your Free Analysis
                <ArrowRight className="group-hover:translate-x-1 transition-transform" size={20} />
              </button>
            </div>
          </div>
        </div>

        {/* Testimonials */}
        <div className="container mx-auto px-4 py-16 bg-white">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-3">
              What Our Users Say
            </h2>
            <p className="text-lg text-gray-600">
              Join thousands of satisfied users who've transformed their smiles
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                <div className="flex items-center gap-3 mb-3">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div>
                    <h4 className="font-semibold text-gray-900">{testimonial.name}</h4>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-600">{testimonial.content}</p>
                <div className="mt-3 flex text-primary-400">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} size={16} fill="currentColor" />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-primary-500 text-white px-6 py-3 rounded-xl hover:bg-primary-600 transition-all inline-flex items-center gap-2 group"
            >
              <Smile className="group-hover:rotate-12 transition-transform" size={20} />
              Join Our Happy Users
            </button>
          </div>
        </div>

        {/* CTA Section */}
        <div className="container mx-auto px-4 py-16 bg-gradient-to-b from-white to-primary-50">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Ready to Transform Your Smile?
            </h2>
            <p className="text-xl text-gray-600 mb-6">
              Get your free smile analysis in just 2 minutes. No registration required.
            </p>
            <div className="flex flex-col items-center gap-4">
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-primary-500 text-white text-lg px-8 py-4 rounded-full hover:bg-primary-600 transition-all transform hover:scale-105 flex items-center gap-2 group"
              >
                <Smile className="group-hover:rotate-12 transition-transform" size={24} />
                Start Your Free Analysis
              </button>
              <p className="text-sm text-gray-500 flex items-center gap-2">
                <Clock size={16} />
                Takes only 2 minutes
                <span className="mx-2">•</span>
                <Shield size={16} />
                100% Private
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
