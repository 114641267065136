import { supabase } from './supabase';

let currentSessionId: string | null = null;

export async function getOrCreateSession(): Promise<string> {
  // Generate a random session ID if none exists
  if (!currentSessionId) {
    currentSessionId = Math.random().toString(36).substr(2, 9);
    localStorage.setItem('sessionId', currentSessionId);
  }
  return currentSessionId;
}

export async function migrateSessionToUser(userId: string): Promise<void> {
  // This is now a no-op since we removed the session tables
  console.log('Session migration skipped - tables removed');
}