import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Smile } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const FAQ: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  
  useEffect(() => {
    if (user) {
      console.log('Current user ID:', user.id);
    }
  }, [user]);
  
  const faqs = [
    {
      question: 'How accurate is the smile analysis?',
      answer: 'Our AI-powered analysis provides highly accurate results based on advanced computer vision algorithms. However, for dental concerns, always consult a dental professional.'
    },
    {
      question: 'Is my photo stored or shared?',
      answer: 'Your privacy is our priority. Photos are processed instantly and not stored on our servers. See our Privacy Policy for more details.'
    },
    {
      question: 'What factors are analyzed?',
      answer: 'We analyze multiple factors including smile symmetry, teeth alignment, smile width, and lip curvature.'
    },
    {
      question: 'How often should I scan my smile?',
      answer: "We recommend monthly scans to track changes in your smile, but you can use the service as often as you'd like."
    },
    {
      question: 'Can I use photos taken with any device?',
      answer: 'Yes, but for best results, use a clear, well-lit photo taken with a modern smartphone or webcam.'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h1>
      <div className="max-w-3xl mx-auto space-y-6">
        {faqs.map((faq, index) => (
          <div key={index} className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
            <p className="text-gray-600">{faq.answer}</p>
          </div>
        ))}
        
        <div className="mt-12 bg-gradient-to-r from-primary-50 to-white p-8 rounded-2xl shadow-sm">
          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-4">Ready to analyze your smile?</h2>
            <p className="text-gray-600 mb-6">Get your personalized smile analysis in minutes.</p>
            <button
              onClick={() => navigate('/')}
              className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2"
            >
              <Smile size={20} />
              Try Free Smile Analysis
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;