import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Smile } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const Terms: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      console.log('Current user ID:', user.id);
    }
  }, [user]);

  return (
    <div className="container mx-auto px-4 py-16 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8">Terms and Conditions</h1>
      
      <div className="prose prose-lg">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
          <p>By accessing and using ScanSmile, you agree to be bound by these Terms and Conditions.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Disclaimer of Liability</h2>
          <p>ScanSmile and the dentists involved accept no responsibility for the current relevance, accuracy, comprehensiveness, or quality of the information provided. No legal claim will be accepted for damages of a material or other nature brought about by use or misuse of the information supplied, provided there is no evidence of intentional or gross negligence.</p>
          <p className="mt-4">We are not liable for:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Accuracy of analysis results</li>
            <li>Service interruptions</li>
            <li>Decisions made based on analysis</li>
            <li>Use of erroneous or incomplete information</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Service Description</h2>
          <p>ScanSmile provides AI-powered smile analysis services. Our analysis is for informational purposes only and should not be considered dental advice. All offers remain subject to confirmation and are non-binding. We reserve the right to modify, replace, or delete parts of the website or the entire service without separate announcement.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property Rights</h2>
          <p>All content and technology on ScanSmile is protected by intellectual property rights. We have made every effort to respect copyrights of the information used in all published material. All brand and product names mentioned are subject to the relevant intellectual property rights of their registered owners.</p>
          <p className="mt-4">The reproduction or use of graphics, sound clips, video sequences, or text in other electronic or printed publications without permission is expressly forbidden.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. External Links</h2>
          <p>We accept no liability for external references or links. ScanSmile has no influence on the content of linked sites and distances itself from all content of linked sites that may have changed after the inclusion of the link. The provider of a linked site is solely liable for illegal, erroneous, or incomplete content.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. User Responsibilities</h2>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide accurate information</li>
            <li>Use the service responsibly</li>
            <li>Not attempt to reverse engineer the service</li>
            <li>Comply with all applicable laws and regulations</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Modifications</h2>
          <p>We reserve the right to modify these terms at any time. Continued use of the service constitutes acceptance of modified terms. Where sections of these terms become invalid, the remaining terms remain unaffected in their content and validity.</p>
        </section>

        <div className="mt-16 bg-gradient-to-r from-primary-50 to-white p-8 rounded-2xl shadow-sm">
          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-4">Ready to get started?</h2>
            <p className="text-gray-600 mb-6">Try our AI-powered smile analysis and receive personalized recommendations.</p>
            <button
              onClick={() => navigate('/')}
              className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2"
            >
              <Smile size={20} />
              Try Free Analysis
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;