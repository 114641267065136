import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Plans from './pages/Plans';
import Success from './pages/Success';
import ThankYou from './pages/ThankYou';
import WorkWithUs from './pages/WorkWithUs';
import Buy from './pages/Buy';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/success" element={<Success />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/work-with-us" element={<WorkWithUs />} />
            <Route path="/buy" element={<Buy />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;