import { loadStripe } from '@stripe/stripe-js';
import { supabase } from '../lib/supabase';

// Initialize Stripe with debugging
const initializeStripe = async () => {
  const stripePublishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
  
  if (!stripePublishableKey) {
    throw new Error('Stripe publishable key is missing.');
  }
  
  console.log('Initializing Stripe with key:', stripePublishableKey.substring(0, 10) + '...');
  const stripe = await loadStripe(stripePublishableKey);
  
  if (!stripe) {
    console.error('Stripe failed to initialize');
    throw new Error('Stripe failed to initialize');
  }

  console.log('Stripe initialized successfully');
  return stripe;
};

// Cache the Stripe promise
const stripePromise = initializeStripe();

// Function to get user's email directly from auth.user
async function getUserEmail(): Promise<string | null> {
  try {
    const { data: { user }, error } = await supabase.auth.getUser();

    if (error) {
      console.error('Error fetching user:', error);
      return null;
    }

    if (!user?.email) {
      console.error('No email found for user');
      return null;
    }

    return user.email;
  } catch (error) {
    console.error('Error in getUserEmail:', error);
    return null;
  }
}

// Function to redirect to Stripe Checkout
export async function redirectToCheckout(priceId: string) {
  console.group('Stripe Checkout Process');
  console.log('Starting checkout with price ID:', priceId);
  
  try {
    const stripe = await stripePromise;

    // Get user's email from auth
    const email = await getUserEmail();
    if (!email) {
      throw new Error('Could not fetch user email');
    }

    console.log('Creating checkout session with:', {
      priceId,
      email,
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/buy`
    });

    // Create the checkout session with the email pre-filled and locked
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: 'payment',
      successUrl: `${window.location.origin}/plans`,
      cancelUrl: `${window.location.origin}/buy`,
      customerEmail: email, // Lock the user's email here
    });

    if (error) {
      console.error('Stripe Checkout Error:', {
        message: error.message,
        type: error.type,
        code: error.code
      });
      throw error;
    }
  } catch (error) {
    console.error('Stripe Redirect Error:', {
      error,
      message: error instanceof Error ? error.message : 'Unknown error'
    });
    throw error;
  } finally {
    console.groupEnd();
  }
}