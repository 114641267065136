import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Smile } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const Privacy: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      console.log('Current user ID:', user.id);
    }
  }, [user]);

  return (
    <div className="container mx-auto px-4 py-16 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      
      <div className="prose prose-lg">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Information Collection and Use</h2>
          <p>When you use ScanSmile, we collect:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Photos you upload or take for analysis</li>
            <li>Device information</li>
            <li>Usage data</li>
            <li>Email address and location when provided</li>
          </ul>
          <p>Where the possibility exists to enter personal or business data (e-mail address, name, address), supplying this information is entirely voluntary.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Data Protection and Security</h2>
          <p>We implement strict security measures:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Photos are processed instantly and not stored permanently</li>
            <li>Encrypted data transmission</li>
            <li>Regular security audits</li>
            <li>Protection against unauthorized access</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Use of Information</h2>
          <p>Your information is used to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide smile analysis services</li>
            <li>Improve our algorithms</li>
            <li>Ensure service quality</li>
            <li>Send requested information and updates</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Third-Party Links</h2>
          <p>We accept no liability for direct or indirect references to external websites ("hyperlinks") outside our responsibility. We have no influence on the design, content, or authorship of linked sites and distance ourselves from all content of linked sites that may have changed after the inclusion of the link.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Communication</h2>
          <p>It is forbidden for third parties to use contact details such as postal address, telephone and fax numbers, or email addresses published on this site for sending unsolicited information. We reserve the right to take legal action against any person sending spam emails in contravention of this prohibition.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Access your personal data</li>
            <li>Request data deletion</li>
            <li>Opt-out of data collection</li>
            <li>Correct inaccurate information</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
          <p>For privacy-related inquiries, contact us at:</p>
          <p>admin@scansmile.com</p>
        </section>

        <div className="mt-16 bg-gradient-to-r from-primary-50 to-white p-8 rounded-2xl shadow-sm">
          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-4">Experience our secure smile analysis</h2>
            <p className="text-gray-600 mb-6">Your privacy is protected with our instant, secure processing.</p>
            <button
              onClick={() => navigate('/')}
              className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2"
            >
              <Smile size={20} />
              Start Free Analysis
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;