import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  LineChart, 
  History, 
  Sparkles, 
  ArrowRight,
  RefreshCw,
  Calendar,
  TrendingUp,
  Scroll,
  Target,
  Image as ImageIcon,
  ArrowUpRight,
  ArrowDownRight,
  Minus,
  ShoppingBag,
  ChevronDown,
  Star,
  Lock,
  CheckCircle2,
  Heart,
  Brain,
  Salad,
  Moon,
  Dumbbell,
  Sun,
  ShoppingCart,
  Smile
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { redirectToCheckout } from '../services/stripe';

const Plans: React.FC = () => {
  const [activeSection, setActiveSection] = useState<'overview' | 'routine' | 'holistic'>('overview');
  const [routineTime, setRoutineTime] = useState<'morning' | 'evening'>('morning');
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleGetAccess = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      if (!user) {
        sessionStorage.setItem('checkoutAfterLogin', 'true');
        window.dispatchEvent(new CustomEvent('openAuthModal'));
        return;
      }

      await redirectToCheckout('price_1QqglwIdDIPcNwoywc4wwul0');
    } catch (error) {
      console.error('Checkout error:', error);
      alert('Failed to initiate checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const sampleResults = [
    {
      label: 'Smile Symmetry',
      current: 75,
      potential: 92,
      description: 'Achieve better smile balance and harmony'
    },
    {
      label: 'Smile Width',
      current: 82,
      potential: 95,
      description: 'Optimize your smile width for a natural look'
    },
    {
      label: 'Teeth Alignment',
      current: 70,
      potential: 88,
      description: 'Enhance the appearance of teeth alignment'
    },
    {
      label: 'Lip Curvature',
      current: 78,
      potential: 90,
      description: 'Perfect your lip positioning for an ideal smile'
    }
  ];

  const getScoreColor = (score: number): string => {
    if (score >= 90) return 'text-green-500';
    if (score >= 80) return 'text-green-400';
    if (score >= 70) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">
              Transform Your Smile
            </h1>
            <p className="text-xl text-gray-600">
              Discover your smile's potential with our advanced analysis and personalized recommendations
            </p>
          </div>

          {/* Sample Results Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-12">
            {sampleResults.map((result, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <h3 className="font-semibold text-gray-900 mb-3">{result.label}</h3>
                <div className="flex items-center justify-between gap-4 mb-2">
                  <div className="text-center">
                    <p className="text-sm text-gray-500 mb-1">Current</p>
                    <p className={`text-2xl font-bold ${getScoreColor(result.current)}`}>
                      {result.current}
                    </p>
                  </div>
                  <ArrowRight className="text-primary-500" size={20} />
                  <div className="text-center">
                    <p className="text-sm text-gray-500 mb-1">Potential</p>
                    <p className={`text-2xl font-bold ${getScoreColor(result.potential)}`}>
                      {result.potential}
                    </p>
                  </div>
                </div>
                <p className="text-sm text-gray-600">{result.description}</p>
              </div>
            ))}
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-6 mb-12">
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <Smile className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Personalized Analysis</h3>
              <p className="text-gray-600">Get detailed insights about your smile and learn how to enhance it.</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <Target className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Custom Recommendations</h3>
              <p className="text-gray-600">Receive tailored advice and tips for your unique smile goals.</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <LineChart className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Progress Tracking</h3>
              <p className="text-gray-600">Monitor your improvement with regular smile assessments.</p>
            </div>
          </div>

          {/* CTA Section */}
          <div className="bg-white rounded-xl shadow-lg p-8 text-center">
            <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Smile?</h2>
            <p className="text-gray-600 mb-6">
              Get started with your personalized smile analysis and improvement plan.
            </p>
            <button
              onClick={handleGetAccess}
              disabled={isProcessing}
              className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2 group disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isProcessing ? (
                <>
                  <RefreshCw className="animate-spin" size={20} />
                  Processing...
                </>
              ) : (
                <>
                  Get Started Now
                  <ArrowRight className="group-hover:translate-x-1 transition-transform" size={20} />
                </>
              )}
            </button>
            <p className="text-sm text-gray-500 mt-4">
              30-day money-back guarantee • Instant access • Secure payment
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;