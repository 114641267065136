import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2, ArrowRight, Smile } from 'lucide-react';

const ThankYou: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-primary-50 via-white to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto text-center">
          <div className="w-20 h-20 bg-primary-100 rounded-full flex items-center justify-center mx-auto mb-8">
            <CheckCircle2 className="text-primary-600 w-12 h-12" />
          </div>
          
          <h1 className="text-4xl font-bold mb-6">
            Thanks!
          </h1>
          
          <p className="text-xl text-gray-600 mb-8">
            We're excited to help you on your smile transformation journey.<br/><br/>
            Check your email for:
          </p>

          <div className="bg-white rounded-xl p-6 shadow-sm mb-8">
            <ul className="space-y-4 text-left">
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Tips for maintaining a healthy smile</span>
              </li>
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Recommended local dentists in your area</span>
              </li>
              <li className="flex items-start gap-3">
                <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={20} />
                <span>Special offers and treatment options</span>
              </li>
            </ul>
          </div>

          <button
            onClick={() => navigate('/')}
            className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2 group"
          >
            <Smile size={20} />
            Return to Home
            <ArrowRight className="group-hover:translate-x-1 transition-transform" size={20} />
          </button>

          <p className="text-sm text-gray-500 mt-6">
            Didn't receive our email? Check your spam folder or contact admin@scansmile.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;