import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import {
  AlertCircle,
  Scan,
  ChevronRight,
  ChevronLeft,
  Loader2,
  CheckCircle2,
  Square,
  RefreshCw,
} from 'lucide-react';
import { analyzeSkinImage, AnalysisResult } from '../services/openai';
import { useNavigate } from 'react-router-dom';
import facescanSvg from '../assets/faceicon.svg';
import { supabase } from '../lib/supabase';

interface PhotoModalProps {
  isOpen: boolean;
  onClose: () => void; // We might still call onClose internally after final submission
}

// Interfaces and constants
interface ScoreCard {
  label: string;
  score: number;
  color: string;
}

const BUDGET_OPTIONS = [
  { value: 2500, label: '£1,000 - £2,500' },
  { value: 5000, label: '£2,500 - £5,000' },
  { value: 10000, label: '£5,000 - £10,000' },
  { value: 15000, label: 'Over £10,000' },
];

const SMILE_CONCERNS = [
  {
    id: 'none',
    label: "I don't have any specific concerns",
    description: 'Just looking to maintain my healthy smile',
    dbValue: 'none',
  },
  {
    id: 'color',
    label: "I'm not happy with the color of my teeth",
    description: 'Including yellowing, staining, or uneven coloring',
    dbValue: 'color',
  },
  {
    id: 'shape',
    label: "I'm concerned about the shape of my teeth",
    description: 'Including size, length, or overall appearance',
    dbValue: 'shape',
  },
  {
    id: 'spacing',
    label: 'I have spacing issues with my teeth',
    description: 'Including gaps, crowding, or uneven spacing',
    dbValue: 'spacing',
  },
  {
    id: 'alignment',
    label: "My teeth aren't as aligned as I'd like",
    description: 'Including crookedness, bite issues, or misalignment',
    dbValue: 'alignment',
  },
];

// Utility: Extract out the postcode prefix
const extractPostcodePrefix = (postcode: string): string => {
  const normalized = postcode.replace(/\s+/g, '').toUpperCase();
  let prefix = '';
  for (let i = 0; i < normalized.length; i++) {
    const char = normalized[i];
    if (/[A-Z]/.test(char)) {
      prefix += char;
    } else {
      break;
    }
  }
  return prefix.toLowerCase();
};

// Utility: Resize image
const resizeImage = (
  imageUrl: string,
  maxWidth: number,
  maxHeight: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      let width = img.width;
      let height = img.height;
      if (width > maxWidth || height > maxHeight) {
        const ratio = Math.min(maxWidth / width, maxHeight / height);
        width *= ratio;
        height *= ratio;
      }
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL('image/jpeg', 0.9));
    };
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = imageUrl;
  });
};

// Loading overlay for analysis
const LoadingOverlay: React.FC = () => (
  <div className="absolute inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center rounded-lg">
    <div className="relative flex flex-col items-center gap-2">
      <div className="w-14 h-14 relative animate-pulse">
        <Scan className="text-white w-full h-full" />
      </div>
      <div className="text-white text-sm font-medium">Analyzing...</div>
    </div>
  </div>
);

// Steps
type Step =
  | 'concerns'
  | 'upload'
  | 'scores'
  | 'colorScore'
  | 'shapeScore'
  | 'spacingScore'
  | 'alignmentScore'
  | 'potentialScores'
  | 'recommendations';

// Simple progress indicator
const ProgressIndicator: React.FC<{ currentStep: number; totalSteps: number }> = ({
  currentStep,
  totalSteps,
}) => {
  return (
    <div className="flex items-center gap-2">
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className={`w-2 sm:w-3 h-2 sm:h-3 rounded-full transition-colors ${
            index + 1 === currentStep ? 'bg-[#24b0ba]' : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  );
};

// Main component
const PhotoModal: React.FC<PhotoModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  // Step state
  const [step, setStep] = useState<Step>('concerns');
  const stepOrder: Step[] = [
    'concerns',
    'upload',
    'scores',
    'colorScore',
    'shapeScore',
    'spacingScore',
    'alignmentScore',
    'potentialScores',
    'recommendations',
  ];
  const totalSteps = stepOrder.length;
  const currentStepIndex = stepOrder.indexOf(step) + 1;

  // Other states
  const [mode, setMode] = useState<'upload' | 'camera'>('upload');
  const [image, setImage] = useState<string | null>(null);
  const [analysisResult, setAnalysisResult] = useState<AnalysisResult | null>(null);
  const [postCode, setPostCode] = useState('');
  const [budget, setBudget] = useState<number | ''>('');
  const [selectedConcerns, setSelectedConcerns] = useState<string[]>([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Webcam reference
  const webcamRef = useRef<Webcam>(null);

  // Refs for progress indicator positioning
  const modalRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [progressTop, setProgressTop] = useState<number>(0);

  // Generate a random session ID
  const [sessionId] = useState(() => Math.random().toString(36).substr(2, 9));

  // Calculate position of the progress indicator on each render
  useEffect(() => {
    const calculateProgressPosition = () => {
      if (modalRef.current && headerRef.current && contentRef.current) {
        const modalTop = modalRef.current.getBoundingClientRect().top;
        const headerBottom = headerRef.current.getBoundingClientRect().bottom;
        const contentTop = contentRef.current.getBoundingClientRect().top;
        // We'll place the progress indicator roughly between the header and content
        const averageY = (headerBottom + contentTop) / 2;
        setProgressTop(averageY - modalTop);
      }
    };

    calculateProgressPosition();
    window.addEventListener('resize', calculateProgressPosition);
    return () => {
      window.removeEventListener('resize', calculateProgressPosition);
    };
  }, [step]);

  if (!isOpen) return null;

  // Handlers
  const handleConcernToggle = (concernId: string) => {
    setSelectedConcerns((prev) => {
      if (concernId === 'none') {
        return prev.includes('none') ? [] : ['none'];
      } else {
        const withoutNone = prev.filter((id) => id !== 'none');
        return withoutNone.includes(concernId)
          ? withoutNone.filter((id) => id !== concernId)
          : [...withoutNone, concernId];
      }
    });
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        if (!reader.result) return;
        const result = reader.result as string;
        const resized = await resizeImage(
          result,
          Math.min(window.innerWidth * 0.8, 800),
          Math.min(window.innerHeight * 0.6, 600)
        );
        setImage(resized);
        setError(null);
        handleAnalysis(resized);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      console.error('File upload error:', err);
      setError('Failed to process the file. Please try again.');
    }
  };

  const capturePhoto = async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      try {
        const resized = await resizeImage(
          imageSrc,
          Math.min(window.innerWidth * 0.8, 800),
          Math.min(window.innerHeight * 0.6, 600)
        );
        setImage(resized);
        setError(null);
        handleAnalysis(resized);
      } catch (err) {
        console.error('Capture photo error:', err);
        setError('Failed to process the photo. Please try again.');
      }
    }
  };

  const handleAnalysis = async (resizedImage: string) => {
    setIsAnalyzing(true);
    setError(null);
    try {
      const result = await analyzeSkinImage(resizedImage);
      setAnalysisResult(result);
      setStep('scores');
    } catch (err) {
      if (
        err instanceof Error &&
        err.message.includes('Smile not clearly visible')
      ) {
        setError(
          'Smile not clearly visible in the image. Please try again with a clear, front-facing smile.'
        );
        setImage(null);
        setAnalysisResult(null);
      } else {
        setError(err instanceof Error ? err.message : 'An unexpected error occurred.');
      }
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleNext = () => {
    if (step === 'upload') return; // We'll rely on the analysis to trigger the next step.
    if (step === 'concerns' && selectedConcerns.length === 0) {
      setError('Please select at least one concern to continue.');
      return;
    }
    const currentIndex = stepOrder.indexOf(step);
    if (currentIndex < stepOrder.length - 1) {
      setStep(stepOrder[currentIndex + 1]);
      setError(null);
    } else if (step === 'recommendations') {
      // If we are already on 'recommendations', that means user clicked "Next" on the final step => let's register
      handleRegister();
    }
  };

  const handleBack = () => {
    const currentIndex = stepOrder.indexOf(step);
    if (currentIndex > 0) {
      setStep(stepOrder[currentIndex - 1]);
      setError(null);
    }
  };

  const handleRegister = async () => {
    // Validation
    if (!postCode) {
      setError('Please enter your post code.');
      return;
    }
    if (!budget) {
      setError('Please select your budget range.');
      return;
    }

    setIsSubmitting(true);
    try {
      // Insert the user's data into Supabase
      if (image && analysisResult) {
        const concernsForDb = selectedConcerns.includes('none')
          ? ['none']
          : selectedConcerns
              .map((id) => SMILE_CONCERNS.find((c) => c.id === id)?.dbValue)
              .filter(Boolean);

        const postDataDb = {
          colour_score: analysisResult.colour.score,
          shape_score: analysisResult.shape.score,
          spacing_score: analysisResult.spacing.score,
          alignment_score: analysisResult.alignment.score,
          post_code: postCode,
          post_code_prefix: extractPostcodePrefix(postCode),
          treatment: analysisResult.treatment,
          concerns: concernsForDb,
          budget,
          image_url: image,
          session: sessionId,
        };

        const { error: dbError } = await supabase
          .from('scansmile_leads')
          .insert([postDataDb]);

        if (dbError) {
          console.error('Error inserting data into Supabase:', dbError);
          throw new Error('Failed to insert analysis data into database');
        }
      }

      // Retrieve the list of practices
      const { data: practices, error: practicesError } = await supabase
        .from('cosmetic_practices')
        .select('*');

      if (practicesError) {
        console.error('Error fetching cosmetic practices:', practicesError);
        throw new Error('Failed to fetch practice details');
      }

      // Decide where to redirect user
      let destinationLink = '';
      if (practices && practices.length > 0) {
        const postCodePrefix = extractPostcodePrefix(postCode);
        for (const practice of practices) {
          const prefixes = practice.post_codes
            .split(',')
            .map((p: string) => p.trim().toLowerCase());
          if (prefixes.includes(postCodePrefix)) {
            destinationLink = practice.unique_link;
            break;
          }
        }
      }

      // Fallback link if no match found
      if (!destinationLink) {
        destinationLink = 'https://link.scansmile.com/westyorkshire';
      }

      // Update the row with the final link
      const { error: updateError } = await supabase
        .from('scansmile_leads')
        .update({ link_visit: destinationLink })
        .eq('session', sessionId);

      if (updateError) {
        console.error('Error updating link_visit:', updateError);
        throw new Error('Failed to update link visit');
      }

      // Send user to the identified link, then close the modal
      window.location.href = destinationLink;
      onClose();
    } catch (err) {
      console.error('Submission error:', err);
      setError('Failed to save your preferences. Please try again.');
      setIsSubmitting(false);
    }
  };

  // Helpers
  const getNextButtonLabel = () => {
    switch (step) {
      case 'concerns':
        return 'Take Photo';
      case 'upload':
        return isAnalyzing ? 'Analyzing...' : 'View Scores';
      case 'scores':
        return 'Color Score';
      case 'colorScore':
        return 'Shape Score';
      case 'shapeScore':
        return 'Spacing Score';
      case 'spacingScore':
        return 'Alignment Score';
      case 'alignmentScore':
        return 'Your Potential';
      case 'potentialScores':
        return 'Recommendations';
      case 'recommendations':
        return isSubmitting ? 'Searching...' : 'Nearby Dentist';
      default:
        return 'Next';
    }
  };

  const getScoreColor = (score: number): string => {
    if (score >= 90) return 'text-green-500';
    if (score >= 80) return 'text-green-400';
    if (score >= 70) return 'text-yellow-500';
    return 'text-red-500';
  };

  const calculatePotentialScore = (score: number): number => {
    return Math.max(82, Math.min(Math.round(score * 1.36), 99));
  };

  const calculateAverageScore = (result: AnalysisResult): number => {
    const scores = [
      result.colour.score,
      result.shape.score,
      result.spacing.score,
      result.alignment.score,
    ];
    return Math.round(scores.reduce((a, b) => a + b, 0) / scores.length);
  };

  const calculateTopPercentage = (averageScore: number): number => {
    // "top X% in the world" text is somewhat arbitrary for demonstration
    return Math.max(0, Math.min(100, 100 - averageScore));
  };

  // Rendering sub-UI
  const renderScoreGrid = (scores: ScoreCard[]) => (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
      {scores.map((item, idx) => (
        <div
          key={idx}
          className="bg-white rounded-lg shadow-sm p-2 flex flex-col items-center justify-center"
        >
          <span className={`text-4xl sm:text-5xl font-bold mb-1 ${item.color}`}>
            {item.score}
          </span>
          <span className="text-sm sm:text-base text-gray-600">
            {item.label}
          </span>
        </div>
      ))}
    </div>
  );

  const renderConcernsStep = () => (
    <div className="space-y-4">
      <div className="space-y-3 max-h-[calc(100vh-300px)] overflow-y-auto">
        {SMILE_CONCERNS.map((concern) => (
          <button
            key={concern.id}
            onClick={() => handleConcernToggle(concern.id)}
            className={`w-full text-left p-3 sm:p-4 rounded-lg border transition-all ${
              selectedConcerns.includes(concern.id)
                ? 'border-[#24b0ba] bg-blue-50'
                : 'border-gray-200 hover:border-blue-200'
            }`}
          >
            <div className="flex items-start gap-3">
              {selectedConcerns.includes(concern.id) ? (
                <CheckCircle2 className="text-[#24b0ba] mt-0.5" size={20} />
              ) : (
                <Square className="text-gray-400 mt-0.5" size={20} />
              )}
              <div>
                <div className="font-medium text-gray-900 text-sm sm:text-base">
                  {concern.label}
                </div>
                <div className="text-xs sm:text-sm text-gray-500 mt-1">
                  {concern.description}
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );

  const renderUploadStep = () => (
    <div className="space-y-4">
      {!image && (
        <div className="flex gap-4 mb-4">
          <button
            onClick={() => setMode('upload')}
            className={`flex-1 p-3 sm:p-4 rounded-lg transition-colors ${
              mode === 'upload'
                ? 'bg-[#24b0ba] text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Upload Photo
          </button>
          <button
            onClick={() => setMode('camera')}
            className={`flex-1 p-3 sm:p-4 rounded-lg transition-colors ${
              mode === 'camera'
                ? 'bg-[#24b0ba] text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Take Photo
          </button>
        </div>
      )}
      <div className="flex justify-center">
        {mode === 'upload' && !image && (
          <label className="w-full h-48 sm:h-64 flex flex-col items-center justify-center border-2 border-dashed border-[#24b0ba] rounded-lg cursor-pointer hover:bg-blue-50 transition-colors">
            <span className="text-sm sm:text-base text-gray-500">
              Click to upload or drag and drop
            </span>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileUpload}
            />
          </label>
        )}
        {mode === 'camera' && !image && (
          <div
            className="relative w-full max-w-xs sm:max-w-sm mx-auto rounded-lg overflow-hidden"
            style={{ maxHeight: '50vh' }}
          >
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="w-full h-auto rounded-md transform scale-x-[-1]"
              videoConstraints={{
                width: { ideal: 640 },
                height: { ideal: 480 },
                facingMode: 'user',
              }}
              onUserMediaError={(err) => {
                console.error('Webcam error:', err);
                setError(
                  'Unable to access the camera. Please ensure camera permissions are enabled.'
                );
              }}
            />
            <img
              src={facescanSvg}
              alt="Face scan overlay"
              className="absolute pointer-events-none"
              style={{
                opacity: 0.25,
                width: '90%',
                height: '90%',
                top: '5%',
                left: '5%',
              }}
            />
            <button
              onClick={capturePhoto}
              className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-[#000000] hover:bg-[#1ea8ac] text-white px-6 py-2 rounded-lg transition-colors"
            >
              Capture Photo
            </button>
          </div>
        )}
        {image && (
          <div className="relative w-full max-w-sm">
            <img
              src={image}
              alt="Preview"
              className="w-full h-auto rounded-lg mx-auto"
              style={{ maxHeight: 'calc(60vh - 200px)' }}
            />
            {isAnalyzing && <LoadingOverlay />}
            {error && (
              <div className="mt-4 flex flex-col gap-3">
                <button
                  onClick={() => {
                    setImage(null);
                    setError(null);
                    if (mode === 'camera' && webcamRef.current?.video) {
                      webcamRef.current.video.play();
                    }
                  }}
                  className="w-full bg-[#24b0ba] text-white py-2 rounded-lg hover:bg-[#1ea8ac] flex items-center justify-center gap-2 transition-colors"
                >
                  <RefreshCw size={20} />
                  {mode === 'camera' ? 'Retake Photo' : 'Upload New Photo'}
                </button>
                <button
                  onClick={() => {
                    setMode(mode === 'camera' ? 'upload' : 'camera');
                    setImage(null);
                    setError(null);
                  }}
                  className="w-full bg-gray-100 text-gray-600 py-2 rounded-lg hover:bg-gray-200 flex items-center justify-center gap-2 transition-colors"
                >
                  {mode === 'camera' ? 'Try Uploading Instead' : 'Try Camera Instead'}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const renderScoresStep = () => {
    if (!analysisResult) return null;
    const averageScore = calculateAverageScore(analysisResult);
    const topPercentage = calculateTopPercentage(averageScore);

    return (
      <div className="space-y-2">
        {image && (
          <div className="flex justify-center mb-2">
            <div className="w-40 h-40 rounded-full overflow-hidden shadow-md">
              <img
                src={image}
                alt="Analyzed Smile"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        )}
        <div className="text-xl sm:text-3xl font-extrabold text-center text-gray-800 mb-4">
          <div>Your smile ranks in the</div>
          <div>top {100 - topPercentage}% in the world</div>
        </div>
        {renderScoreGrid([
          {
            label: 'Color',
            score: analysisResult.colour.score,
            color: getScoreColor(analysisResult.colour.score),
          },
          {
            label: 'Shape',
            score: analysisResult.shape.score,
            color: getScoreColor(analysisResult.shape.score),
          },
          {
            label: 'Spacing',
            score: analysisResult.spacing.score,
            color: getScoreColor(analysisResult.spacing.score),
          },
          {
            label: 'Alignment',
            score: analysisResult.alignment.score,
            color: getScoreColor(analysisResult.alignment.score),
          },
        ])}
      </div>
    );
  };

  const renderDetailStep = () => {
    if (!analysisResult) return null;
    let category;
    switch (step) {
      case 'colorScore':
        category = analysisResult.colour;
        break;
      case 'shapeScore':
        category = analysisResult.shape;
        break;
      case 'spacingScore':
        category = analysisResult.spacing;
        break;
      case 'alignmentScore':
        category = analysisResult.alignment;
        break;
      default:
        return null;
    }

    return (
      <div className="space-y-4">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          {category.description.split('\n\n').map((point, index) => {
            const trimmedPoint = point.trim();
            let symbol = '';
            let symbolColor = '';
            let restText = trimmedPoint;
            if (trimmedPoint.startsWith('+')) {
              symbol = '+';
              symbolColor = 'text-emerald-700';
              restText = trimmedPoint.slice(1);
            } else if (trimmedPoint.startsWith('-')) {
              symbol = '-';
              symbolColor = 'text-rose-700';
              restText = trimmedPoint.slice(1);
            }
            return (
              <p key={index} className="text-sm sm:text-base text-gray-600 mb-2">
                {symbol && <span className={`${symbolColor} font-bold`}>{symbol}</span>}
                {restText}
              </p>
            );
          })}
        </div>
        <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm">
          <div className="text-center">
            <p className="text-xs sm:text-sm text-gray-500 mb-1">Current</p>
            <p className={`text-4xl sm:text-5xl font-bold ${getScoreColor(category.score)}`}>
              {category.score}
            </p>
          </div>
          <ChevronRight className="text-[#24b0ba]" size={24} />
          <div className="text-center">
            <p className="text-xs sm:text-sm text-gray-500 mb-1">Potential</p>
            <p
              className={`text-4xl sm:text-5xl font-bold ${getScoreColor(
                calculatePotentialScore(category.score)
              )}`}
            >
              {calculatePotentialScore(category.score)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderPotentialStep = () => {
    if (!analysisResult) return null;
    return (
      <div className="space-y-4">
        <p className="text-sm sm:text-base text-gray-600 text-center">
          With the right dentist, you could see these improved scores:
        </p>
        {renderScoreGrid([
          {
            label: 'Color',
            score: calculatePotentialScore(analysisResult.colour.score),
            color: getScoreColor(calculatePotentialScore(analysisResult.colour.score)),
          },
          {
            label: 'Shape',
            score: calculatePotentialScore(analysisResult.shape.score),
            color: getScoreColor(calculatePotentialScore(analysisResult.shape.score)),
          },
          {
            label: 'Spacing',
            score: calculatePotentialScore(analysisResult.spacing.score),
            color: getScoreColor(calculatePotentialScore(analysisResult.spacing.score)),
          },
          {
            label: 'Alignment',
            score: calculatePotentialScore(analysisResult.alignment.score),
            color: getScoreColor(calculatePotentialScore(analysisResult.alignment.score)),
          },
        ])}
      </div>
    );
  };

  const renderRecommendationsStep = () => (
    <div className="space-y-4">
      <div className="bg-white rounded-lg p-4 shadow-sm mb-4">
        <p className="text-gray-600">{analysisResult?.treatment}</p>
        <br />
        <p className="font-bold">
          Enter your details below to find the best nearby cosmetic dentist for you:
        </p>
      </div>
      <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto">
        <div>
          <label className="block text-sm font-bold text-gray-700 mb-1">Post Code</label>
          <input
            type="text"
            value={postCode}
            onChange={(e) => setPostCode(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24b0ba] focus:border-transparent text-sm sm:text-base"
            placeholder="Enter your post code"
            maxLength={10}
          />
        </div>
        <div>
          <label className="block text-sm font-bold text-gray-700 mb-1">
            Dental Treatment Budget
          </label>
          <select
            value={budget}
            onChange={(e) => setBudget(Number(e.target.value))}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#24b0ba] focus:border-transparent text-sm sm:text-base"
          >
            <option value="">Select your budget range</option>
            {BUDGET_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  // Top-level switch for main display
  const renderStepContent = () => {
    switch (step) {
      case 'concerns':
        return renderConcernsStep();
      case 'upload':
        return renderUploadStep();
      case 'scores':
        return renderScoresStep();
      case 'colorScore':
      case 'shapeScore':
      case 'spacingScore':
      case 'alignmentScore':
        return renderDetailStep();
      case 'potentialScores':
        return renderPotentialStep();
      case 'recommendations':
        return renderRecommendationsStep();
      default:
        return null;
    }
  };

  const getHeaderTitle = () => {
    switch (step) {
      case 'concerns':
        return 'Your Smile Concerns';
      case 'upload':
        return 'Upload Your Smile';
      case 'scores':
        return (
          <span className="text-3xl sm:text-4xl font-bold">
            <span className="text-[#24b0ba]">Scan</span>
            <span className="ml-1">Smile.com</span>
          </span>
        );
      case 'colorScore':
        return 'Color Score Analysis';
      case 'shapeScore':
        return 'Shape Score Analysis';
      case 'spacingScore':
        return 'Spacing Score Analysis';
      case 'alignmentScore':
        return 'Alignment Score Analysis';
      case 'potentialScores':
        return 'Potential Smile Scores';
      case 'recommendations':
        return 'Find A Dentist Today';
      default:
        return 'ScanSmile.com';
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div
        ref={modalRef}
        className="relative bg-white rounded-2xl w-full max-w-2xl max-h-[85vh] flex flex-col"
      >
        <div ref={headerRef} className="flex items-center justify-between p-4">
          <h2 className="font-bold text-left">{getHeaderTitle()}</h2>

          {/* 
            Subtle button in top right corner with a tooth emoji
            that takes the user directly to the "recommendations" step
          */}
          <button
            onClick={() => setStep('recommendations')}
            className="text-gray-800 hover:text-[#24b0ba] transition-colors"
            disabled={isSubmitting}
            aria-label="Go to recommendations"
          >
            🦷
          </button>
        </div>

        {/* Position our progress indicator */}
        {progressTop !== 0 && (
          <div
            style={{
              position: 'absolute',
              top: progressTop,
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <ProgressIndicator currentStep={currentStepIndex} totalSteps={totalSteps} />
          </div>
        )}

        {/* Error message */}
        {error && (
          <div className="mx-4 mt-4 p-3 bg-red-50 border border-red-200 rounded-lg flex items-start gap-2">
            <AlertCircle className="text-red-500 flex-shrink-0 mt-0.5" size={18} />
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}

        {/* Main content */}
        <div ref={contentRef} className="flex-1 overflow-y-auto p-4">
          {renderStepContent()}
        </div>

        {/* Footer buttons */}
        <div className="flex justify-between p-4 border-t border-gray-100">
          {step !== 'concerns' && (
            <button
              onClick={handleBack}
              className="text-gray-600 hover:text-gray-800 px-4 py-2 rounded-lg flex items-center gap-2 transition-colors"
              disabled={isSubmitting}
            >
              <ChevronLeft size={20} />
              <span className="text-sm sm:text-base">Previous</span>
            </button>
          )}
          <button
            onClick={handleNext}
            disabled={isSubmitting || step === 'upload'}
            className={`${
              step === 'concerns' ? 'ml-auto' : ''
            } bg-[#24b0ba] text-white px-6 py-2 rounded-lg shadow-lg hover:bg-[#1ea8ac] hover:shadow-xl flex items-center gap-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            <span className="text-sm sm:text-base">{getNextButtonLabel()}</span>
            {step === 'upload' && isAnalyzing ? (
              <Loader2 className="animate-spin" size={20} />
            ) : (
              <ChevronRight size={20} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhotoModal;
