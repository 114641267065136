import React from 'react';
import { Mail, CheckCircle2, Users, TrendingUp, Target } from 'lucide-react';

const WorkWithUs: React.FC = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:admin@scansmile.com?subject=Work%20with%20Us%20-%20Dental%20Practice%20Inquiry';
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-primary-50 via-white to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          {/* CTA Section */}
          <div className="bg-white rounded-xl p-8 shadow-lg border border-primary-100 text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Ready to Grow Your Practice?
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Partner with ScanSmile to connect with qualified patients seeking cosmetic dental treatments
            </p>
            <button
              onClick={handleEmailClick}
              className="bg-primary-500 text-white px-8 py-4 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-105 inline-flex items-center gap-2 group"
            >
              <Mail size={20} />
              Contact Us Today
            </button>
            <p className="text-sm text-gray-500 mt-4">
              We will respond within 12 hours
            </p>
          </div>

          {/* Benefits Grid */}
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <Users className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Qualified Leads</h3>
              <p className="text-gray-600">Connect with patients who are actively seeking cosmetic dental treatments and have been pre-qualified through our platform.</p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <Target className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Targeted Marketing</h3>
              <p className="text-gray-600">Reach patients specifically interested in cosmetic dentistry services in your local area.</p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <TrendingUp className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Practice Growth</h3>
              <p className="text-gray-600">Expand your cosmetic dentistry services with a steady stream of interested patients.</p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-sm">
              <div className="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-4">
                <CheckCircle2 className="text-primary-600" size={24} />
              </div>
              <h3 className="text-lg font-semibold mb-2">Quality Assurance</h3>
              <p className="text-gray-600">All leads are verified and pre-screened to ensure high-quality patient matches for your practice.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;