import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  CheckCircle2, 
  Sparkles, 
  ArrowRight, 
  Star, 
  Shield, 
  Clock, 
  TrendingUp,
  Users,
  RefreshCw
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { redirectToCheckout } from '../services/stripe';
import { supabase } from '../lib/supabase';

const Buy: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCheckingPurchase, setIsCheckingPurchase] = useState(true);
  const [hasPurchased, setHasPurchased] = useState(false);

  useEffect(() => {
    if (user) {
      checkPurchaseStatus();
    } else {
      setIsCheckingPurchase(false);
    }
  }, [user]);

  const checkPurchaseStatus = async () => {
    try {
      const { data: purchases, error } = await supabase
        .from('stripe_purchases')
        .select('*')
        .eq('email', user?.email)
        .eq('status', 'complete')
        .limit(1);

      if (error) throw error;
      
      if (purchases && purchases.length > 0) {
        setHasPurchased(true);
        navigate('/plans');
      }
    } catch (error) {
      console.error('Error checking purchase status:', error);
    } finally {
      setIsCheckingPurchase(false);
    }
  };

  const handleCheckout = async () => {
    console.group('Buy Page Checkout');
    console.log('Checkout initiated. User state:', { isAuthenticated: !!user, email: user?.email });
    
    if (isProcessing) {
      console.log('Checkout already in progress');
      console.groupEnd();
      return;
    }

    setIsProcessing(true);

    try {
      if (!user) {
        console.log('User not authenticated, storing checkout intent');
        sessionStorage.setItem('checkoutAfterLogin', 'true');
        navigate('/', { state: { showAuth: true } });
        return;
      }

      const { data: purchases, error } = await supabase
        .from('stripe_purchases')
        .select('*')
        .eq('email', user.email)
        .eq('status', 'complete')
        .limit(1);

      if (error) throw error;

      if (purchases && purchases.length > 0) {
        navigate('/plans');
        return;
      }

      console.log('Proceeding to Stripe checkout');
      await redirectToCheckout('price_1QqglwIdDIPcNwoywc4wwul0');
    } catch (error) {
      console.error('Checkout error:', error);
      alert('Failed to initiate checkout. Please try again.');
    } finally {
      setIsProcessing(false);
      console.groupEnd();
    }
  };

  if (isCheckingPurchase) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <RefreshCw className="animate-spin text-primary-500" size={32} />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-primary-50 via-white to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Your Personalized Smile Guide
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Transform your smile with science-backed recommendations tailored to your unique needs
            </p>
            <div className="flex items-center justify-center gap-4 text-sm text-gray-500">
              <div className="flex items-center gap-1">
                <Users size={16} />
                <span>2,000+ active users</span>
              </div>
              <span>•</span>
              <div className="flex items-center gap-1">
                <Star size={16} fill="currentColor" />
                <span>4.9/5 rating</span>
              </div>
            </div>
          </div>

          {/* Main Content Grid */}
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            {/* Left Column - Features */}
            <div className="space-y-6">
              <h2 className="text-2xl font-semibold mb-6">What You'll Get</h2>
              
              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                  <TrendingUp className="text-primary-500" />
                  Personalized Improvement Plan
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={18} />
                    <span className="text-gray-600">Custom smile enhancement routine</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={18} />
                    <span className="text-gray-600">Personalized recommendations for your smile goals</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={18} />
                    <span className="text-gray-600">Step-by-step improvement guide</span>
                  </li>
                </ul>
              </div>

              <div className="bg-white rounded-xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
                  <Clock className="text-primary-500" />
                  30-Day Transformation Guide
                </h3>
                <ul className="space-y-3">
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={18} />
                    <span className="text-gray-600">Weekly progress tracking tools</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={18} />
                    <span className="text-gray-600">Lifestyle and care recommendations</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <CheckCircle2 className="text-primary-500 flex-shrink-0 mt-1" size={18} />
                    <span className="text-gray-600">Expert tips for maintaining results</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Right Column - Pricing */}
            <div className="bg-white rounded-xl p-8 shadow-lg border border-primary-100">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h2 className="text-2xl font-bold">Limited Time Offer</h2>
                  <p className="text-gray-600">Unlock your personalized guide today</p>
                </div>
                <Sparkles className="text-primary-500" size={24} />
              </div>

              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-gray-500 line-through">$99.99</span>
                  <span className="bg-primary-100 text-primary-600 px-3 py-1 rounded-full text-sm font-medium">
                    50% OFF
                  </span>
                </div>
                <div className="text-4xl font-bold text-primary-600">$49.99</div>
                <p className="text-sm text-gray-500 mt-1">One-time payment</p>
              </div>

              <div className="space-y-4 mb-8">
                <div className="flex items-center gap-2 text-gray-600">
                  <Shield size={20} className="text-primary-500" />
                  <span>30-day money-back guarantee</span>
                </div>
                <div className="flex items-center gap-2 text-gray-600">
                  <Sparkles size={20} className="text-primary-500" />
                  <span>Instant digital access</span>
                </div>
              </div>

              <button
                onClick={handleCheckout}
                disabled={isProcessing}
                className="w-full bg-primary-500 text-white py-3 rounded-xl hover:bg-primary-600 transition-all transform hover:scale-[1.02] flex items-center justify-center gap-2 group mb-4 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="font-medium">
                  {isProcessing ? 'Processing...' : user ? 'Get Started Now' : 'Sign In to Continue'}
                </span>
                <ArrowRight className="group-hover:translate-x-1 transition-transform" size={20} />
              </button>

              <p className="text-xs text-center text-gray-500">
                Secure payment powered by Stripe
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <div className="grid md:grid-cols-3 gap-6">
            {[
              {
                name: 'David R.',
                content: 'The personalized recommendations transformed my smile. I feel more confident than ever!',
                rating: 5
              },
              {
                name: 'Sarah M.',
                content: 'Finally, a science-based approach to smile enhancement. The improvement in just 30 days was remarkable.',
                rating: 5
              },
              {
                name: 'James L.',
                content: 'Worth every penny! The custom routine helped me achieve the smile I always wanted.',
                rating: 5
              }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="flex text-primary-400 mb-2">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <Star key={i} size={16} fill="currentColor" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                <p className="font-medium text-gray-900">{testimonial.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buy;